import React from "react";
// import aboutImage from "img/about.js"; // Assuming about.jpg is in the correct directory

export const About = (props) => {
  return (
    <section id="about" className=" md:h-[600px] w-full flex justify-center items-center bg-gray-100">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-6/12 px-4">
            <img src='img/portfolio/01-large.jpg' className="mx-auto rounded-lg shadow-lg" alt="About Us" />
          </div>
          <div className="w-full md:w-6/12 px-4">
            <div className="about-text">
              <h2 className="text-3xl font-bold mb-4">About Us</h2>
              <p className="mb-4">{props.data ? props.data.paragraph : "Loading..."}</p>
              <h3 className="text-xl font-bold mb-2">Why Choose Us?</h3>
              <div className="flex flex-wrap">
                <div className="w-full md:w-full px-4 mb-4">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`} className="mb-2">
                            {d}
                          </li>
                        ))
                      : "Loading..."}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
