import React from "react";

const features = [
    {
        title: "Global Talent Pipeline",
        description: "We ensure a robust pipeline of skilled pharmacists through international recruitment, addressing the shortage of qualified professionals in the industry.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
            </svg>

        ),
    },
    {
        title: "Tailored Advertising Strategies",
        description: "Our strategic advertising services empower independent pharmacies to compete effectively against larger chains, enhancing their market presence.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z"/>
            </svg>


        ),
    },
    {
        title: "Service Diversification Guidance",
        description: "We provide expert consultancy to guide SMEs in diversifying their services, building resilience and adaptability in a dynamic market.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z"/>
            </svg>

        ),
    },
    {
        title: "Simplified Accreditation",
        description: "Eridupharma simplifies the accreditation process for international pharmacists, facilitating a smooth transition into the Canadian healthcare system.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M7 6H5m2 3H5m2 3H5m2 3H5m2 3H5m11-1a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2M7 3h11a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm8 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
            </svg>

        ),
    },
    {
        title: "AI & ML Integration",
        description: "We integrate advanced AI and ML technologies to provide personalized recommendations and enhance service delivery efficiency, particularly for rural pharmacies.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 18.5A2.493 2.493 0 0 1 7.51 20H7.5a2.468 2.468 0 0 1-2.4-3.154 2.98 2.98 0 0 1-.85-5.274 2.468 2.468 0 0 1 .92-3.182 2.477 2.477 0 0 1 1.876-3.344 2.5 2.5 0 0 1 3.41-1.856A2.5 2.5 0 0 1 12 5.5m0 13v-13m0 13a2.493 2.493 0 0 0 4.49 1.5h.01a2.468 2.468 0 0 0 2.403-3.154 2.98 2.98 0 0 0 .847-5.274 2.468 2.468 0 0 0-.921-3.182 2.477 2.477 0 0 0-1.875-3.344A2.5 2.5 0 0 0 14.5 3 2.5 2.5 0 0 0 12 5.5m-8 5a2.5 2.5 0 0 1 3.48-2.3m-.28 8.551a3 3 0 0 1-2.953-5.185M20 10.5a2.5 2.5 0 0 0-3.481-2.3m.28 8.551a3 3 0 0 0 2.954-5.185"/>
            </svg>

        ),
    },
    {
        title: "Market Innovation",
        description: "Our innovative approach helps the Canadian pharmacy sector leverage advanced technology, driving growth and efficiency.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 15v5m-3 0h6M4 11h16M5 15h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1Z"/>
            </svg>

        ),
    },
    {
        title: "Continuous Support",
        description: "We offer ongoing support and guidance to our clients, ensuring they have the resources and knowledge to excel in their operations.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 9h.01M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM6.6 13a5.5 5.5 0 0 0 10.81 0H6.6Z"/>
            </svg>


        ),
    },
    {
        title: "Expertise and Leadership",
        description: "Led by a highly qualified team, Eridupharma brings deep expertise and leadership to the pharmacy sector, fostering a community of thriving healthcare professionals.",
        icon: (
            <svg className="w-10 h-10 text-gray-200 dark:text-gray-950 font-extralight" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                      d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
            </svg>

        ),
    },
];

const Feature = ({title, description, icon}) => (
    <div className="flex flex-col items-center gap-4">
        <div className="rounded-full p-4">{icon}</div>
        <div className="grid gap-2">
            <h3 className="font-semibold">{title}</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
        </div>
    </div>
);

export const Services = () => {
    return (
        <section id="services" className="w-full py-12 md:py-24 lg:py-32 flex justify-center items-center">
            <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10">
                <div className="space-y-3">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Our Key Features</h2>
                    <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                        Discover the powerful features that make our platform the best choice for your business.
                    </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8 lg:gap-10">
                    {features.map((feature, index) => (
                        <Feature key={index} {...feature} />
                    ))}
                </div>
            </div>
        </section>
    );
};
