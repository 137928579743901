import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import Intro from "./components/intro";
import { Me } from "./components/me";
import { Contact } from "./components/contact";
import Faqs from "./components/faqs";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLandingPageData(JsonData);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // Set loading to false after 3 seconds

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    return (
        <div className="flex flex-col w-full justify-center">
            {loading ? (
                <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                    <img alt="content" className="object-cover p-0 object-center h-30 mb-4" src="img/logo.svg" />
                    <div className="loader">
                        <div className="dot "></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            ) : (
                <>  
                    <Navigation />
                    <Header data={landingPageData.Header} />
                    <Intro />
                    <Features data={landingPageData.Features} />
                    <About data={landingPageData.About} />
                    <Services data={landingPageData.Services} />
                    <Faqs />
                    <Me data={landingPageData.Team} />
                    <Contact data={landingPageData.Contact} />
                </>
            )}
        </div>
    );
};

export default App;



{/* <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                    <video className="object-cover p-0 object-center h-30 mb-4" src="intro/vide_intro.mp4" autoPlay loop muted/>
                </div> */}