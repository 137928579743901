import { useRef, useState } from "react"
import {Link as ScrollLink, animateScroll as scroll} from 'react-scroll';

const FaqsCard = (props) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')
    const { faqsList, idx } = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <section
            className="space-y-3 mt-5 w-full overflow-hidden border-b w-full "
            key={idx}
            onClick={handleOpenAnswer}
            id={'faqs'}
        >
            <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
                {faqsList.q}
                {
                    state ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                    )
                }
            </h4>
            <div
                ref={answerElRef} className="duration-300"
                style={state ? {height: answerH } : {height: '0px'}}
            >
                <div>
                    <p className="text-gray-500">
                        {faqsList.a}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default () => {

    const faqsList = [
        {
            q: "What is your mission and vision?",
            a: " Our vision is to empower pharmacies for a healthier tomorrow by revolutionizing the healthcare industry with innovative solutions, continuous guidance, and comprehensive resources. Our mission is to equip every pharmacy, regardless of size or location, with the knowledge, tools, and support needed for unparalleled success, fostering a community of thriving healthcare professionals committed to delivering exceptional services and shaping the future of pharmacy practice."
        },
        {
            q: "How do you support independent pharmacies?",
            a: "Through tailored advertising and strategic media services."
        },
        {
            q: "Can you help with international accreditation?",
            a: ": Yes, we simplify the accreditation process for international pharmacists."
        },
        {
            q: "Do you offer ongoing support?",
            a: "Yes, we provide continuous support and guidance."
        },
        {
            q: "Who leads Eridupharma?",
            a: "Abdulaziz Ghanim Al-Shiblawi is a distinguished pharmacist with a robust academic and professional background. He holds a Bachelor's degree from Rajiv Gandhi University of Health Sciences, where he laid the foundation for his extensive expertise in the pharmaceutical industry. Abdulaziz's career is marked by his role as a Clinical Pharmacist at Al Furat Al Awsat Oncology Center, a position he has held since May 2021, where he applies his knowledge to improve patient care in oncology."
        }
    ]
  
    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-semibold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, Still confused? feel free to contact us.
                </p>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                            idx={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}