import React from "react";

export const Me = (props) => {
    return <section id={'me'} class="text-gray-600 w-full body-font">
        <div class="container px-5 py-24 mx-auto flex flex-col">
            <div class="lg:w-4/6 mx-auto">
                <div class="rounded-lg w-full h-64 overflow-hidden">
                    <img alt="content" class="object-cover p-0 object-center h-full w-full"
                        src="img/meImage.png" />
                </div>
                <div class="flex flex-col sm:flex-row mt-10">
                    <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                        <div
                            class="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </div>
                        <div class="flex flex-col items-center text-center justify-center">
                            <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">Abdulaziz Ghanim Al-Shiblawi (Chief Executive Officer)</h2>
                            <div class="w-12 h-1 bg-[#0b788a] rounded mt-2 mb-4"></div>
                            {/* <p class="text-base">Abdulaziz Ghanim Al-Shiblawi is a Clinical Pharmacist at Al Furat Al Awsat Oncology Center since May 2021. He has a Bachelor's from Rajiv Gandhi University and certifications from the WHO and Fortis Hospital. He previously managed Beirut and Al Astika Pharmacies and worked at Nexel, Pezita, and Cipla Pharma</p> */}
                        </div>
                    </div>
                    <div
                        class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <p class="leading-relaxed text-lg mb-4">Abdulaziz Ghanim Al-Shiblawi is a highly qualified pharmacist with a Bachelor's degree from Rajiv Gandhi University of Health Sciences. Currently serving as a Clinical Pharmacist at Al Furat Al Awsat Oncology Center since May 2021, Abdulaziz brings his extensive expertise to the healthcare sector. He has held pivotal roles as Pharmacy Manager at Beirut Pharmacy and Al Astika Pharmacy since January 2018, and as a Medical Representative at Nexel Pharma, Pezita Pharma, and Cipla Pharma. His professional development includes certifications from the World Health Organization and Fortis Hospital, enhancing his skills in clinical pharmacy, oncology pharmacy, and pharmacovigilance. Fluent in Arabic, English, and Hindi, Abdulaziz excels in fostering effective communication and collaboration across multicultural environments, making him a valuable asset in the pharmaceutical industry.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>
};
