import React from "react";

export const Features = (props) => {
  return (
    <section id="features" className="text-center flex w-full  justify-center items-center md:h-[600px] ">
      <div className="container">
        <div className="section-title mb-8">
          <h2 className="text-3xl font-semibold">Features</h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="text-center">
                  <i className={`${d.icon} text-5xl text-[#0b788a] mb-4 mx-auto`}></i>
                  <h3 className="text-xl font-semibold mb-2">{d.title}</h3>
                  <p className="text-gray-600">{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </section>
  );
};

export default Features;
