import React, { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c0nmimj",
        "template_2t1q7ge",
        e.target,
        "O9PFPFVv-ZRMev8l6"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <section id="contact" className="py-12 w-full ">
        <div className="container mx-auto">
          <div className="section-title mb-8 text-center">
            <h2 className="text-3xl font-bold">Get In Touch</h2>
            <p className="mt-2">
              Please fill out the form below to send us an email and we will get back to you as soon as possible.
            </p>
          </div>
          <div className="flex justify-center">
            <form
              name="sentMessage"
              validate
              onSubmit={handleSubmit}
              className="w-full max-w-lg"
            >
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="message"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-[#1a777a] hover:bg-[#0b788a] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
          <div className="flex justify-center mt-8">
            <div className="contact-info">
              <div className="contact-item">
                <h3 className="text-xl font-semibold">Contact Info</h3>
                <p>
                  <span className="text-gray-700">
                    <i className="fa fa-map-marker mr-2"></i> Address
                  </span>{" "}
                  {props.data ? props.data.address : "Loading..."}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span className="text-gray-700">
                    <i className="fa fa-phone mr-2"></i> Phone
                  </span>{" "}
                  {props.data ? props.data.phone : "Loading..."}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span className="text-gray-700">
                    <i className="fa fa-envelope-o mr-2"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "Loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <div className="social">
              <ul className="flex">
                <li className="mr-4">
                  <a
                    href={props.data ? props.data.facebook : "/"}
                    className="text-gray-700 hover:text-blue-500"
                  >
                    <i className="fa fa-facebook fa-lg"></i>
                  </a>
                </li>
                <li className="mr-4">
                  <a
                    href={props.data ? props.data.twitter : "/"}
                    className="text-gray-700 hover:text-blue-500"
                  >
                    <i className="fa fa-twitter fa-lg"></i>
                  </a>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
