import React, { Component } from 'react';

class Intro extends Component {
    render() {
        return (
            <section id="intro" className="bg-gray-100 py-20">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-4xl tracking-tight font-extralight text-gray-950">
                            Eridupharma Consultants: Revolutionizing the Pharmacy Sector in Canada
                        </h2>
                        <p className="mb-4">
                            Eridupharma Consultants, a pioneering Canadian technology company dedicated to revolutionizing the pharmacy sector. Our mission is to empower pharmacies across Canada by leveraging cutting-edge technology and innovative solutions. From streamlining global pharmacy recruitment to enhancing independent pharmacies through advanced advertising, guiding SMEs in tech-driven service diversification, simplifying international accreditation online, and utilizing AI for personalized recommendations, we are committed to advancing healthcare excellence together. Join us as we lead the way in transforming the pharmacy industry for a healthier tomorrow.
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-8">
                        <img className="w-full h-96 object-cover rounded-lg object-bottom " src="img/inroducion_1.png" alt="office content 1" />
                        <video className="w-full h-96 object-cover rounded-lg object-bottom" src="intro/introducion_2.mp4" autoPlay loop muted />
                    </div>
                </div>
            </section>
        );
    }
}

export default Intro;
