import React from "react";

export const Header = (props) => {
  return (
    <section id="header">
      <header
        style={{ backgroundImage: `url('img/intro-bg2.png')` }}
        id="header"
        className="relative bg-cover bg-top w-full h-[700px]"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center z-30">
          <h1 className="text-4xl md:text-6xl font-bold text-white overflow-hidden whitespace-nowrap relative">
            <span className="block animate-typewriter">Eridupharma Consultants</span>
          </h1>
        </div>
      </header>
    </section>
  );
};

export default Header;
