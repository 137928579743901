import React, { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

export const Navigation = () => {
    const [activeSection, setActiveSection] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const sections = document.querySelectorAll("section");

        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.3 // Adjust based on when you want to trigger the active state
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.getAttribute("id"));
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sections.forEach((section) => observer.observe(section));

        return () => {
            sections.forEach((section) => observer.unobserve(section));
        };
    }, []);

    const handleSetActiveSection = (sectionId) => {
        setActiveSection(sectionId);
        setIsMenuOpen(false); // Close the menu when an item is clicked
        scroll.scrollTo(sectionId, {
            smooth: true,
            duration: 500,
        });
    };

    return (
        <nav className="bg-white fixed top-0 left-0 z-40 w-full shadow">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center py-4">
                    <div className="flex items-center">
                        <button
                            type="button"
                            className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 md:hidden"
                            aria-label="Toggle navigation"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <svg className="w-6 h-6 transition-transform duration-300 transform" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                            </svg>
                        </button>
                        <a className="text-xl font-bold text-gray-800" href="#page-top">
                            <img alt="content" className="object-cover p-0 object-center h-14" src="img/logo.svg" />
                        </a>
                    </div>
                    <div
                        className={`md:flex md:items-center md:w-auto w-full transition-all duration-300 ease-in-out ${isMenuOpen ? 'block' : 'hidden'}`}
                    >
                        <ul className="flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0">
                            {[
                                { href: "intro", label: "Introduction" },
                                { href: "features", label: "Features" },
                                { href: "about", label: "About" },
                                { href: "services", label: "Services" },
                                { href: "faqs", label: "Faqs" },
                                { href: "me", label: "Me" },
                                { href: "contact", label: "Contact" },
                            ].map((item) => (
                                <li key={item.href} className="md:ml-4">
                                    <ScrollLink
                                        to={item.href}
                                        spy={true}
                                        smooth={true}
                                        offset={item.href === 'intro' ?-200  :item.href === 'faqs' ? -340 : item.href === 'features' ? -100 : item.href === 'about' ? -150 : 100}
                                        duration={500}
                                        onClick={() => handleSetActiveSection(item.href)}
                                        className={`block md:inline-block px-4 py-2 cursor-pointer transition-colors duration-300 ${activeSection === item.href
                                            ? "text-[#0b788a] border-b-2 border-[#0b788a]"
                                            : "text-gray-700 hover:text-gray-900"
                                            }`}
                                    >
                                        {item.label}
                                    </ScrollLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};
